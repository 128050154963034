// Body
// $body-bg: #f8fafc;

// Typography
// $font-family-sans-serif: 'Nunito', sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;

// Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;
//
// $warning: $orange;

// Original Danger
$original-danger: #dc3545;



// Info: #67c2d0
$info: #67c2d0;

// Success: #72c585
$success: #72c585;

// Secondary: #9fa4aa
$secondary: #9fa4aa;

// Warning: #ffd65d
$warning: #ffd65d;

// Primary: #58a8ff
$primary: #58a8ff;
