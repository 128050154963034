
// Style Color Overrides
.bg-info,
.bg-info > a { color: #fff!important; }

.bg-success,
.bg-success > a { color: #fff!important; }

.btn-outline-success:hover {
  color: #fff;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
}

.bg-secondary,
.bg-secondary > a { color: #fff!important; }

.bg-primary,
.bg-primary > a { color: #fff!important; }

.btn-primary {
  color: #fff;
}


// Sidebar Menu Styles
[class*="sidebar-dark-"] {
  background-color: $original-danger;

  .brand-link {
    border-bottom-color: #fff;
  }

  .sidebar a {
    color: #fff;
  }

  .nav-treeview > .nav-item > .nav-link {
    color: #fff;
  }

  .nav-item .nav-link {
    transition: all ease .3s;
  }
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.9);
  color: #343a40;
}

.sidebar-dark-primary .nav-sidebar > .nav-item.menu-open > .nav-link.active {
  background-color: $original-danger;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}






// Helper Styles
.border-dashed { border-style: dashed!important; }

// Card Header - Remove ":after" selector
.card-header {
  &.clean {
    &:after {
      display:none!important;
    }
  }
}


// Fix Pagination Styles
body .pagination > nav div:nth-child(2) > div:nth-child(1) { display: none!important; }
body .pagination > nav > div:nth-child(1) { display: none; }
body .pagination > nav > div:nth-child(2) > div:nth-child(2) > span > span[aria-disabled="true"] > span > svg,
body .pagination > nav > div:nth-child(2) > div:nth-child(2) > span > a[rel="prev"] svg,
body .pagination > nav > div:nth-child(2) > div:nth-child(2) > span > a[rel="next"] svg { max-width: 24px; }
body .pagination {
  width: 100%;

  > nav {
    width: 100%;
    text-align: right;

    > div:nth-child(2) {

      > div:nth-child(2) {

        span {

          // active pagination
          >span {

            > span {
              background: $primary!important;
              color: $white!important;
            }
          }
        }
      }
    }
  }


}


// Animation Rotate
.rotate {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
